import './loader.css';
const Loader = () => {
	return (
		<div className='loaderContainer'>
			<svg
				width='140'
				height='140'
				viewBox='0 0 200 200'
				fill='none'
				className='entireLogo'>
				<path
					className='borderPath'
					d='M97.5 26.4434C99.047 25.5502 100.953 25.5502 102.5 26.4434L162.452 61.0566C163.999 61.9498 164.952 63.6004 164.952 65.3868V134.613C164.952 136.4 163.999 138.05 162.452 138.943L102.5 173.557C100.953 174.45 99.047 174.45 97.5 173.557L37.5481 138.943C36.0011 138.05 35.0481 136.4 35.0481 134.613V65.3868C35.0481 63.6004 36.0011 61.9498 37.5481 61.0566L97.5 26.4434Z'
					stroke='var(--green)'
					strokeWidth='8'
				/>
				<path
					className='letterP'
					d='M102.094 105.234H83.8594V97.875H102.094C105.625 97.875 108.484 97.3125 110.672 96.1875C112.859 95.0625 114.453 93.5 115.453 91.5C116.484 89.5 117 87.2188 117 84.6562C117 82.3125 116.484 80.1094 115.453 78.0469C114.453 75.9844 112.859 74.3281 110.672 73.0781C108.484 71.7969 105.625 71.1562 102.094 71.1562H85.9688V132H76.9219V63.75H102.094C107.25 63.75 111.609 64.6406 115.172 66.4219C118.734 68.2031 121.438 70.6719 123.281 73.8281C125.125 76.9531 126.047 80.5312 126.047 84.5625C126.047 88.9375 125.125 92.6719 123.281 95.7656C121.438 98.8594 118.734 101.219 115.172 102.844C111.609 104.438 107.25 105.234 102.094 105.234Z'
					fill='var(--green)'
					strokeWidth='8'
				/>
			</svg>
		</div>
	);
};
export default Loader;
